<script setup lang="ts">
import AtomsIcon from "../icon/index.vue";

const props = withDefaults(
  defineProps<{
    type: "page" | "summary";
    label: string;
    description?: string;
    backButton?: {
      defaultBackRoute: string;
    };
    loading?: {
      title?: boolean;
      description?: boolean;
    };
    clickable?: {
      title?: boolean;
      description?: boolean;
    };
    alignItems?: 'items-start' | 'items-center';
  }>(),
  {alignItems: 'items-start'}
);
const emit = defineEmits<{
  (e: "click-title"): void;
  (e: "click-description"): void;
}>();

const router = useRouter();
const route = useRoute();
const { previousRoute } = usePreviousRouteStore();

const onBack = () => {
  if (!props.backButton) return;
  if (previousRoute && route.path !== previousRoute.path) {
    router.back();
    return;
  }
  router.replace({
    name: props.backButton.defaultBackRoute,
  });
};
</script>

<template>
  <div :class="['title-component', type, alignItems]">
    <slot name="icon">
      <AtomsTooltip title="Voltar" side="bottom">
        <button @click="onBack" v-if="backButton">
          <AtomsIcon name="chevron-left" class="text-white" :size="24" />
        </button>
      </AtomsTooltip>
    </slot>
    <div class="w-full flex flex-col items-start">
      <AtomsSkeleton v-if="loading?.title" class="w-2/5 !mt-2" />
      <h2 v-else :class="{ clickable: clickable?.title }" :tabindex="clickable?.title ? '0' : ''"
        @click="clickable?.title ? emit('click-title') : null">
        {{ label }}
      </h2>
      <AtomsSkeleton v-if="loading?.description" class="w-1/5 !mt-2" />
      <h3 v-else-if="
        !loading?.description && (description || $slots['description'])
      " :class="{ clickable: clickable?.description }" :tabindex="clickable?.description ? '0' : ''"
        @click="clickable?.description ? emit('click-description') : null">
        <slot name="description">
          {{ description }}
        </slot>
      </h3>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-component {
  @apply text-primary flex gap-2;

  h2 {
    @apply font-bold;

    &.clickable {
      @apply cursor-pointer;

      &:hover {
        @apply underline;
      }
    }
  }

  h3 {
    &.clickable {
      @apply cursor-pointer;

      &:hover {
        @apply underline;
      }
    }
  }

  button {
    @apply bg-primary hover:bg-primary-dark transition-colors rounded-full w-[32px] h-[32px] mt-[2px] flex items-center justify-center;
  }

  &.page {
    h2 {
      @apply text-[26px] 2xl:text-[36px] leading-[34px];
    }

    h3 {
      @apply text-[14px] 2xl:text-[16px];
    }
  }

  &.summary {
    h2 {
      @apply text-[20px] 2xl:text-[24px];
    }

    h3 {
      @apply text-[14px] 2xl:text-[16px];
    }
  }
}
</style>
