<template>
  <div class="skeleton"></div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.skeleton {
  @apply min-w-[20%] min-h-4 my-[2px] rounded-2xl;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
